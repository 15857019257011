import React from "react";
import "./PosBill.css";
import { Card } from "react-bootstrap";
import redeemcard from "../images/Credit Reedem.svg";
import logo from "../images/logo-new.png";

const Reedem = ({ creditnoteid, creditnoteamount, creditavailableamount }) => {
  return (
    <div>
      <Card
        className="text-center"
        style={{ backgroundColor: "#E9F8FF", border: "none" }}
      >
        <Card.Body>
          <Card.Img
            className="card-img"
            variant="top"
            src={redeemcard}
            style={{ width: "18rem" }}
          />
          <Card.Title
            className="card-title"
            style={{
              fontSize: "1.7rem",
              color: "#143A4D",
              fontWeight: "600",
              marginTop: "12px",
            }}
          >
            Credit Reedem Succesfully
          </Card.Title>
          <Card.Text
            className="card-text"
            style={{ fontSize: "1.1rem", color: "#143A4D", fontWeight: "500" }}
          >
            Credit No: <span> {creditnoteid}</span>&nbsp;You have
            <br />
            Reedem <span>{creditnoteamount}</span> of credit Your new
            <br />
            Credit Balance is <span> {creditavailableamount} </span>Thankyou for
            <br />
            Choosing to show with us!
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Reedem;
