import React from "react";
import "./PosBill.css";
import TimeDisplay from "./TimeDisplay";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import profileicon from "../images/user icon.svg";
import callicon from "../images/Call icon.svg";
function Header({ customerdetail }) {
  
  return (
    <Container fluid className="bill-header px-0">
      <div className="billheadercontainer">
      
      <Navbar className="d-flex w-100">
        <Container fluid className="justify-content-between">
          <div className="profile-header">
            <img className="profile-icon" src={profileicon} alt="" />
            &nbsp;&nbsp;
            <span>{(customerdetail[0]) ? (customerdetail[0]) : ("Walk in Customer") }</span>
          </div>

          <div className="profile-header d-flex">
            <span>
              <img src={callicon} alt="" /> {(customerdetail[1]) ? (customerdetail[1]) : ("Walk in Customer") }
            </span>
          </div>
          <div className="profile-header d-flex loyalty-points">
            <span>Loyalty-Points: {customerdetail[2] && customerdetail[2] !== "-" ? customerdetail[2] : 0}</span>
          </div>

          <div className="profile-header ">
          <TimeDisplay />
          </div>
        </Container>
      </Navbar>
      </div>
    </Container>
    
  );
}

export default Header;
