import React, { useState, useEffect } from "react";
import { BsQrCodeScan } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import "../components/PosBill.css"

const QrCode = ({ qrcodeimg ,setImgSrc}) => {
  const [timer, setTimer] = useState(75);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(interval); // Clear the interval first

      // Perform actions after timer reaches 0
      setImgSrc(null); // Assuming this sets the image source to null
      toast.error('OOpPSSSSSSSSS !', {
        position: toast.POSITION.TOP_CENTER
      });
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <div>
      <div
        className="text-center"
        style={{ backgroundColor: "#E9F8FF", border: "none" }}
      >
       
            <img
              variant="top"
              src={"data:image/png;base64," + qrcodeimg}
              style={{
                width: "17rem",
                height: "18rem",
              }}
            />
        
          <div>
            <p
              style={{
                marginTop: "12px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#143A4D",
              }}
            >
              Scan To Pay <span style={{fontSize:"28px"}}><BsQrCodeScan/></span> 
            </p>
            <p
              style={{
                marginTop: "-9px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#143A4D",
              }}
            >
              Request timeout within :<span style={{color:'red'}}> {timer} </span>seconds.
            </p>
          </div>
      </div>
    </div>
  );
};

export default QrCode;
