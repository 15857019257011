import React from "react";
import "./PosBill.css";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer({ billDetail }) {
  return (
    <div className="bill-footer">
      <Container fluid className="amount-bill">
        <Row className="justify-content-md-center align-items-center">
          <Col className="col-md-2">
          <div className="quantity-div">
              <p className="qty-count">
                Qty: <span>{billDetail.totalqty}</span>
              </p>
            </div>
          </Col>
          <Col className="col-md-3">
          <p>
              Flat-Discount: <span>% {billDetail.flatdiscount}</span>
            </p>
          </Col>
          <Col className="col-md-3">
          <p className="tax-amount">
              Tax: <span>&#8377;{billDetail.taxamount}</span>
            </p>
          </Col>

          <Col className="col-md-3">
            {
              billDetail.totalqty==0 ? (
                <p>
            
                Add Charges: <span>&#8377; {billDetail.additionalcharge=0}</span>{" "}
              </p>
              ):(
                <p>
            
                Add Charges: <span>&#8377; {billDetail.additionalcharge}</span>{" "}
              </p>
              )
            }
         
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <hr className="footer-dotted-line" />
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-md-center align-items-center">
          <Col >
          <p className="mrp-amount">
              MRP:<span>&#8377;{billDetail.subtotal}</span>
            </p>
          </Col>
          <Col >
          <p className="discount-amount">
              Bill Discount: <span>&#8377; {billDetail.totaldiscount}</span>{" "}
            </p>
          </Col>
          <Col >
          <p className="round-off ">
              Round off: <span>{billDetail.roundoff}</span>
            </p>
          </Col>
          <Col >
          {
              billDetail.totalqty == 0 ? (
                <div className="total-input">
                  <p>
                    TOTAL: <span>&#8377;0</span>
                  </p>
                </div>
              ) : billDetail.creditnote_applier_amount == 0 ? (
                <div className="total-input">
                  <p>
                    TOTAL: <span>&#8377;{billDetail.totalMrp}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <small className="credit-difference">
                    ({billDetail.creditnote_net_Amount}-{billDetail.creditnote_applier_amount})
                  </small>
                  <div className="total-input">
                    <p>
                      TOTAL: <span>&#8377;{billDetail.credit_net_total_amount}</span>
                    </p>
                  </div>
                </div>
              )
           }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
