import React, { useState, useEffect } from "react";
import "./PosBill.css";

function TimeDisplay() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();
  let ampm = hours >= 12 ? "PM" : "AM";


  const hours12 = hours % 12 || 12;


  const minutesFormatted = minutes.toString().padStart(2, "0");
  const secondsFormatted = seconds.toString().padStart(2, "0");


  const time = `${hours12}:${minutesFormatted}:${secondsFormatted} ${ampm}`;

  
  const date = `${currentTime.getDate()}/${currentTime.getMonth() + 1}/${currentTime.getFullYear()}`;

  return (
    <div className="timestamp" >
      <span>{date}</span>&nbsp;&nbsp;|&nbsp;
      <span>{time}</span>
    </div>
  );
}

export default TimeDisplay;
