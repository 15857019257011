import React from 'react'
import { Card} from 'react-bootstrap'
import cart from "../images/Pay later.svg"

const PayLater = ({terms,paylaterdate}) => {
  return (
    <div>
      
    <Card className='text-center '  style={{ backgroundColor:"#E9F8FF",border:"none" }}>
      
      <Card.Body >
      <Card.Img variant="top" src={cart}style={{width:"16rem"}} />
        <Card.Text style={{fontSize:"1.2rem",color:"#143A4D",fontWeight:"500",marginTop:"1rem"}} >
       Terms: {terms}
        </Card.Text>
        <Card.Text style={{fontSize:"1.2rem",color:"#143A4D",fontWeight:"700"}}>
       Due Date: <span>{paylaterdate}</span>
        </Card.Text>
     
      </Card.Body>
    </Card>
    </div>
  )
}

export default PayLater
