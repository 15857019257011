import React from "react";
import { Card } from "react-bootstrap";
import cash from "../images/Cash.svg";
import "./PosBill.css";
import cards from "../images/Card.svg";

const PaymentMethod = ({ type }) => {
  console.log(type, "typeeee");
  return (
    <div>
      <Card
        className="text-center"
        style={{ backgroundColor: "#E9F8FF", border: "none" }}
      >
        {type === "SaveCashFromtendered_btn" ? (
          <Card.Body style={{ marginTop: "45px" }}>
            <Card.Img
              className="card-img"
              variant="top"
              src={cash}
              style={{ width: "18rem" }}
            />
            <Card.Text
              className="card-text"
              style={{
                fontSize: "1.4rem",
                color: "#143A4D",
                fontWeight: "500",
              }}
            >
              Thankyou for shopping With us.
            </Card.Text>
          </Card.Body>
        ) : (
          <Card.Body>
            <Card.Img
              className="card-img"
              variant="top"
              src={cards}
              style={{ width: "18rem" }}
            />
            <Card.Text
              className="card-text"
              style={{
                fontSize: "1.4rem",
                color: "#143A4D",
                fontWeight: "500",
              }}
            >
              Thankyou for shopping With us. Just To Confirm  That Your Payment
              method was CARD.
            </Card.Text>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default PaymentMethod;
