import React from 'react'
import { Card } from 'react-bootstrap'
import logo from "../images/logo-new.png"
import coupon from "../images/Coupon.svg"
const Coupon = () => {
  return (
    <div>
         <Card className='text-center' style={{ backgroundColor:"#E9F8FF",border:"none" }}>
       
       <Card.Body >
         <Card.Img variant='top' src={coupon}style={{width:"23rem",}}  />
         <Card.Text style={{marginTop:"15px",fontSize:"23px",fontWeight:"500",color:"#143A4D"}}>
          Coupon Applied <span style={{fontWeight:"800"}}>Yay!!</span>
         </Card.Text>
       </Card.Body>
  
     </Card>
      
    </div>
  )
}

export default Coupon
